.alert-container {
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 736px;
}
.alert-container span {
  font-weight: 700;
}
.alert-container i {
  font-size: 24px;
  line-height: 24px;
  overflow: hidden;
}
