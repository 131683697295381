:root[data-theme="light"] {
  --primary: #00566e;
  --secondary: #3c3c3c;
  --success: #147a11;
  --info: #0084e3;
  --warning: #e1bd00;
  --danger: #980107;
  --light: #f8f9fa;
  --dark: #2c2c2c;
  --disabled: #989898;
  --background:#f8f9fa;
  --text: #000;
  --text-white: #fff;
  --dark-text:var(--dark);
  --primary-text: var(--primary);
  --welcome-page-goal: #f4f4f4;
  --inactive-button: #D9D9D9;
}
:root[data-theme="dark"] {
  --primary: #00566e;
  --secondary: #fff;
  --success: #147a11;
  --info: #0084e3;
  --warning: #e1bd00;
  --danger: #980107;
  --light: #f8f9fa;
  --dark: #2c2c2c;
  --disabled: #989898;
  --background: #2c2c2c;
  --text: #fff;
  --text-white: #3c3c3c;
  --dark-text: #fff;
  --primary-text: #fff;
  --welcome-page-goal: #2c2c2c;
  --inactive-button: var(--dark);
}

body {
  margin: 0 10px;
  font-family: Oxygen, Barlow;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
}

*::selection {
  color: #fff;
  background-color: var(--primary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
