body {
  overflow-x: hidden;
}
.welcome-page-wrapper {
  position: relative;
  background-color: var(--background);
  max-width: 100vw;
}
.welcome-page-wrapper::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  z-index: -1;
}

.welcome-container {
  font-family: Oxygen;
  position: relative;
  z-index: 1;
}

.hidden {
  display: none;
}
.slider-container {
  position: relative;
  overflow: hidden;
  height: auto;
  min-height: 360px;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.background-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.slide-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 50px; */
  z-index: 1;
  width: 100%;
  max-width: 1110px; 
  margin: 0 auto; 
  margin-top: 40px;
}

.slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  flex-grow: 1;
  margin-top: 40px;
}
.slide-text {
  /* color: var(--dark-text); */
  color: white;
  font-family: Barlow;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  max-width: 620px;
  line-height: 42px;
  text-align: center;
  margin: 0;
}

.slide-button {
  background-color: var(--dark-text);
  color: var(--background);
  width: 540px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: Barlow;
  font-style: normal;
  font-weight: 700;
}

.slide-button:hover {
  opacity: 0.9;
}

.slide-arrow {
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.2s;
}

.right-arrow {
  transform: rotate(180deg);
}

.slide-indicators {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 2;
}

.slide-indicator {
  width: 16px;
  height: 16px;
  background-color: #f4f4f4;
  border-radius: 50%;
  cursor: pointer;
}

.slide-indicator.active {
  background-color: var(--primary);
}
.poem-container {
  position: relative;
  overflow: hidden;
  height: auto;
  min-height: 320px;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}
.welcome-text {
  color: var(--text);
}
.poem {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  max-width: 1110px;
  padding: 70px 20px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  color: var(--text);
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
  font-weight: 200;
  line-height: 25px;
  padding-left: 90px;
}
.shultz {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  text-align: start;
  margin-top: 20px;
}

.diag-logo-bg {
  position: relative;
  background-color: var(--primary);
}
.diag-logo-bg::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background-color: var(--primary);
  z-index: -1;
}
.diag-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.diag-logo-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-items: center;
  margin-top: 55px;
  margin-bottom: 44px;
  text-decoration: none;
}
.diag-logo-img {
  height: 41px;
  margin-bottom: 24px;
}
.diag-logo-text {
  margin-top: 14px;
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
.diag-h2 {
  color: var(--secondary);
  font-family: Barlow;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.diag-h2-1 {
  margin-top: 85px;
  margin-bottom: 50px;
}
.diag-h2-2 {
  margin-top: 115px;
  margin-bottom: 40px;
}
.diag-h2-3 {
  margin-top: 115px;
  margin-bottom: 25px;
}
.vision-container {
  display: flex;
  flex-direction: row;
  /* height: 505px; */
}
.vision-text-container {
  width: 540px;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 400;
}
.diag-task-container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  /* margin-top: 150px; */
  margin-left: 125px;
  gap: 30px;
}
.diag-task {
  background-color: var(--primary);
  width: 45vw;
  height: 110px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}
.goals-container {
  width: 100%;
  font-size: 13px;
}
.goals-row {
  display: flex;
  width: 100%;
}
.goal-item {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.goal-img {
  width: 20%;
  background-color: var(
    --welcome-page-goal
  ); /* background for not even fields */
}
.goal-desc {
  font-size: 13px;
  width: 30%;
  background-color: var(--primary);
  color: white;
}
/* background for even fields */
.reverse-bg {
  background-color: var(--primary);
  color: white;
}
.goal-img img {
  max-width: 100%;
  height: auto;
}
/* empty elements */
.goal-item.goal-img:empty,
.goal-item.goal-desc:empty {
  background-color: transparent;
}
.goals-row:nth-child(odd) .goal-img,
.goals-row:nth-child(even) .goal-desc {
  background-color: var(--primary);
  color: white;
}
.goals-row:nth-child(odd) .goal-desc,
.goals-row:nth-child(even) .goal-img {
  /* background-color: var(--welcome-page-goal); */
  background-color: transparent;
  color: var(--text);
}
.goals-row.last-row .goal-item.empty {
  background-color: transparent;
}
.diag-tools {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.diag-tools-container {
  display: flex;
  flex-direction: row;
  gap: 23px;
  align-content: center;
}
.diag-tools-link {
  /* color: #0084e3; */
  /* text-decoration:none; */
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
  padding: 0;
}

.partners-logos-bg {
  position: relative;
  background-color: var(--background);
}

.partners-logos-bg::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background-color: white;
  z-index: -1;
}

.partners-logos {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  background-color: white;
  padding: 20px 0;
}

.partners-logos a {
  display: flex;
  justify-content: center;
  margin: 0;
  width: 190px;
}

/* footer */
.footer-container {
  all: unset;
  padding-top: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--dark-text);
  font-family: Oxygen;
  font-size: 13px;
  margin-top: 60px;
  margin-bottom: 30px;
}
.copyright-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--primary-text);
  font-size: 13px;
}

.copyright-button {
  all: unset;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.copyright-logo {
  height: 30px;
}

.copyright-text {
  all: unset;
  font-weight: 700;
  text-align: start;
  margin: 0px;
  padding: 0px;
  margin-left: important 0px;
}

.footer-msl {
  all: unset;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 200px;
  text-align: center;
  font-weight: 400;
  border: none;
  background: none;
  /* cursor: pointer; */
}

/* header */
button {
  all: unset;
}
.diagnosis {
  height: 54px;
  width: auto;
  margin-right: -88px;
}
.header-container {
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
}

.header-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.header-logotypes {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  z-index: 0;
  gap: 35px;
}
.sponsor-logos-container {
  position: relative;
  overflow: hidden;
  max-width: 600px;
  padding: 0 15px;
}

.sponsor-logos-scroll {
  display: flex;
  will-change: transform;
}

.sponsor-logo-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
}

.sponsor-logo-item img {
  width: 100%;
  height: auto;
  display: block;
}

@media (min-width: 768px) {
  .header-flex {
    flex-direction: row;
    justify-content: space-between;
  }

  .header-logotypes {
    width: 100%;
  }

  .sponsor-logos {
    justify-content: flex-end;
  }

  .footer-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}
@media (max-width: 768px) {
  .slide-container {
    padding: 0 0px;
    width: 90%;
  }

  .slide-text {
    font-size: 20px;
  }
  .slide-button {
    width: 95%;
  }
}

@media (max-width: 480px) {
  .sponsor-logos {
    gap: 5px;
  }
  .sponsor-logos img {
    max-width: 80px;
  }
  .slide-container {
    padding: 0 10px;
  }

  .slide-text {
    font-size: 16px;
  }
  .slide-button {
    width: 100%;
  }
  .slide-indicators {
    display: none;
  }
}

@media (max-width: 1150px) {
  .slide-container {
    padding: 0 0px;
  }
}
@media (max-width: 1400px) {
  .vision-container {
    flex-direction: column;
    height: auto;
  }

  .vision-text-container {
    width: 100%;
  }

  .diag-task-container {
    margin-left: 0;
    margin-top: 30px;
  }
  .diag-task {
    width: 50vw;
    height: auto;
    padding: 15px;
  }
}
@media (max-width: 768px) {
  .welcome-page-wrapper {
    overflow: hidden;
  }
  .diag-logo {
    flex-direction: column;
    align-items: center;
  }

  .diag-logo-container {
    margin: 20px 0;
  }

  .vision-container {
    flex-direction: column;
    height: auto;
  }

  .vision-text-container {
    width: 100%;
  }

  .diag-task-container {
    margin-left: 0;
    margin-top: 30px;
  }

  .diag-task {
    width: 100%;
    height: auto;
    padding: 15px;
  }

  .goals-row {
    flex-direction: column;
  }

  .goal-item {
    width: 100%;
  }

  .goal-img,
  .goal-desc {
    width: 100%;
  }

  .poem {
    padding-left: 20px;
  }
  .partners-logos a {
    width: 170px;
  }
}

@media (max-width: 480px) {
  .welcome-container {
    width: 100vw;
  }
  .diag-logo {
    width: 100vw;
    right: 0;
  }
  .diag-logo-button {
    width: 100%;
  }

  .poem {
    font-size: 12px;
  }
  .shultz {
    font-size: 12px;
  }

  .diag-task {
    width: 90%;
  }
  .vision-text-container {
    width: 90%;
  }
  .diag-h2 {
    font-size: 28px;
  }
  .goals-container {
    width: 100%;
  }
  .goal-item {
    width: 90%;
    font-size: 15px;
  }

  .goal-img,
  .goal-desc {
    width: 90%;
  }
}
