.lang {
  display: flex;
  justify-content: flex-end;
}
.lang-button {
  display: flex;
  padding: 0px 10px;
  font-family: Oxygen;
  gap: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 28.5px; /* 203.571% */
  color: var(--dark-text);
  background-color: transparent;
  border: 0;
  padding-left: 0;
}
.lang-button1 {
  font-family: Oxygen;
  font-size: 14px;
  font-weight: 700;
  line-height: 24.5px;
  color: var(--primary-text);
  background-color: transparent;
  border: 0;
}
.lang-button.active {
  color: var(--primary-text);
}
