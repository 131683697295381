.app-container {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
  font-family: Oxygen;
  color: var(--dark-text);
  font-weight: 400;
  font-size: 16px;
}

.box {
  align-items: flex-start;
  min-height: calc(90vh - 85px);
}
.norm {
  line-height: 26.5px;
  max-width: 1000 px;
  margin-top: 30px;
}
h4 {
  margin: auto;
}
.color-converter {
  all: unset;
  font-size: 26px;
}
.title {
  display: flex;
  color: var(--text);
  font-family: Barlow;
  font-size: 20px;
  font-weight: 700;
  margin-top: 30px;
}
.colorText {
  margin-top: 30px;
  font-size: 20px;
  font-family: Barlow;
  font-weight: 400;
  margin-left: 0;
  width: max-content;
}
.table-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 750px;
}
.title-lab {
  font-size: 18px;
}
.title-result {
  font-size: 24px;
}

.converter-container {
  margin-top: 20px;
  max-width: 750px;
}

form {
  margin-bottom: 20px;
}
input{
  max-width: 750px;
}
.input-forms-container{
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 600px) {
  .input-forms-container{
    display: flex;
    flex-direction: column;
  }
}
.input-box{
  max-width: 750px;
}

.conversion-table {
  width: 100%;
  border-collapse: collapse;
  color: var(--text);
}

th,
td {
  border: 1px solid #ddd;
  padding: 3px;
  padding-left: 15px;
  text-align: left;
}
@media only screen and (max-width: 600px) {
  th,
  td {
    border: 1px solid #ddd;
    padding: 5px;
    padding-left: 10px;
    text-align: left;
  }
}
.no-border th,
.no-border td {
  border: none;
}
.tableHeaderCell {
  background: var(--disabled);
  font-size: 16px;
  font-weight: normal;
  line-height: 28.5px;
}
.table-style {
  font-size: 16px;
  line-height: 24.5px;
  background: var(--text-whtie);
  color: var(--dark-text);
}
.plusCircle-icon {
  justify-content: flex-start;
}
button {
  cursor: pointer;
}
.addLabButton {
  background-color: transparent;
  border: 0;
  margin-top: 20px;
  font-family: Oxygen;
  color: var(--primary-text);
  font-size: 16px;
  font-weight: 700;
  line-height: 24.5px;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plusCircle-icon {
  margin-right: 10px;
  color: var(--primary);
}

.resutlsPreview {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-left: 20px;
  font-family: Oxygen;
  font-size: 13px;
  color: var(--secondary);
}
.resutlsPreview-text-container {
  display: flex;
  flex-direction: row;
}
.resutlsPreview-color-text {
  width: 175px;
  margin-bottom: 20px;
}
.resutlsPreview-color-text2 {
  color: var(--text);
}
.resutlsPreview-color-container {
  display: flex;
  flex-direction: row;
}
.resutlsPreview-color {
  margin-top: 10px;
  height: 110px;
  width: 175px;
}
.goToButton {
  margin-top: 15px;
  padding: 10px;
  border-color: var(--secondary);
  border-width: 1px;
  background-color: transparent;
  font-family: Oxygen;
  font-size: 13px;
  font-weight: 700;
  color: var(--secondary);
}
.delta-note {
  margin-left: 0;
  font-size: 13px;
}
.method-select {
  display: flex;
  flex-wrap: wrap;
  background-color: #D9D9D9;
  padding: 15px;
  gap: 15px;
}

.method-select label {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: 100%;
}
.multiple-input-title{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.xyy-checkbox {
  accent-color: var(--primary);
}