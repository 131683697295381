.footer {
  color: var(--dark-text);
  border-top: 1px solid;
  max-width: 1150px;
  height: 65px;
  /*display: inline-flex;*/
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-top: 85px;  
}
.footer-left {
  display: flex;
  align-self: center;
  cursor: pointer;
}
.logofooter {
  width: 67.887px;
  height: 25px;
  left: 0;
  align-self: center;
}
p {
  line-height: 24.5px;
  margin-left: 25px;
}
.footer-right {
  display: flex;
  align-self: center;
  justify-self: flex-end;
  height: 21;
  line-height: 24.5px;
  text-align: center;
}
a {
  margin-right: 50px;
  cursor: pointer;
}
strong {
  font-weight: 700;
  line-height: 24.5px;
}
@media only screen and (max-width: 600px) {
  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-left,
  .footer-right {
    margin-bottom: 15px;
  }
  a {
    margin-right: 15px;
    padding-bottom: 15px;
  }
}
