.delta-container {
  font-size: 16px;
  line-height: 28.5px;
  display: flex;
  flex-direction: column; 
}
.delta-results {
  text-align: left;
  width: 288px;
  height: auto;
  color: var(--dark);
  margin-top: 40px;
  margin-bottom: 20px;
  margin-right: 30px;
}
.delta-colors {
  display: flex;
  flex-direction: row;
  color: var(--secondary);
  margin-top: 20px; 
  font-family: Barlow;
  font-size: 16px;
  font-weight: 400;
}
.deltacolor {
  width: 375px;
  height: 111px;
  flex-shrink: 0;
  margin-top: 10px;
}
@media only screen and (max-width: 600px) {
  .deltacolor {
    width: 180px;
  }
}
