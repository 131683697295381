label {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: nowrap;
}
.color{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.switch-value{
  color: var(--primary-text);
  font-weight: 700;
  display: inline;
  margin-left: 0;
}

.lab-form-input-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.lab-input {
  width: 100px;
  height: 30px;
  flex-shrink: 0;
  border: 1px solid #e5e5e5;
  font-size: 13px;
  font-weight: 400;
  line-height: 24.5px;
}

.lab-input:focus-visible {
  outline: none;
  border-color: var(--primary);
}

.clear-button {
  all: unset;
  font-family: Barlow;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 0;
  background-color: var(--disabled);
  cursor: pointer;
  transition: 0.25s;
  height: 30px;
  padding-right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clear-button:hover {
  background-color: var(--secondary);
  color: white;
  transition: 0.4s;
}
.switch-button {  
  all: unset;
  font-family: Barlow;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 0;
  background-color: var(--primary);
  cursor: pointer;
  transition: 0.25s;
  margin-left: 15px;
  height: 30px;
  padding-right: 14px;
  padding-top: 1px;
  padding-bottom: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
}
.switch-button-delete {  
  all: unset;
  font-family: Barlow;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 0;
  background-color: var(--danger);
  cursor: pointer;
  transition: 0.25s;
  margin-left: 20px;
  height: 30px;
  padding-right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-icon{
  margin-left: 10px;
  margin-right: 14px;
}
input {
  padding-left: 10px;
}

.lab-form-select-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* flex-wrap: wrap; */
  margin-top: 20px;
}
.white_value {
  margin-top: 20px;
}
