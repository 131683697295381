.preview-text {
  color: var(--dark-text);
  font-size: 13px;
  font-weight: 400;
  line-height: 24.5px;
  margin-top: 20px;
}
.color-preview {
  max-width: 750px;
  height: 70px;
}
.hex {
  color: var(--text);
  font-size: 16px;
  font-weight: 700;
  line-height: 24.5px;
  margin-top: 25px;
  margin-bottom: 15px;
  text-align:start;
  align-self: center;
}
.IconFiles {
  margin-left: 15px;
  cursor: pointer;
  vertical-align: middle;
}
.xyz {
  color: var(--text);
  text-align: start;
  font-size: 16px;
  font-weight: 400;
  line-height: 28.5px;
}
