.header {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--text);
}
.header-button {
  display: flex;
  align-items: center;
}
.diaglogo {
  width: 144.457px;
  height: 32px;
  flex-shrink: 0;
}
h1 {
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: center;
  justify-self: center;
  color: var(--dark-text);
  margin-left: 40px;
}
.shultz {
  font-size: 12px;
  line-height: 14.5px;
  font-weight: 200;
  text-align: right;
  font-family: "Inter";
  font-style: italic;
}
.theme-toggle-button {
  all: unset;
  border-style: solid;
  border-width: 1px;
  border-radius: 80px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 10px;
  font-weight: 400;
  padding-top: 4px;
  padding-bottom: 4px;
}

.theme-toggle-dark {
  border-color: white;
  color: white;
  padding-right: 16px;
  padding-left: 2px;
}

.theme-toggle-light {
  border-color: var(--dark);
  color: var(--dark);
  padding-left: 16px;
  padding-right: 2px;
}
.mainMenu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.mainMenu-button {
  all: unset;
  display: flex;
  padding: 0px 10px;
  font-family: Oxygen;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 28.5px;
  color: var(--dark-text);
  background-color: transparent;
  border: 0;
  padding-left: 0;
  cursor: pointer;
}
.mainMenu-space {
  background-color: var(--text);
}
.mainMenu-button1 {
  font-family: Oxygen;
  font-size: 14px;
  font-weight: 700;
  line-height: 24.5px;
  color: var(--primary-text);
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
.konwerter-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
}

.konwerter-menu-button {
  all: unset;
  font-family: Barlow;
  color: var(--dark-text);
  background-color: var(--inactive-button);
  font-size: 16px;
  font-weight: 700;
  border-radius: 0;
  cursor: pointer;
  transition: 0.25s;
  height: 30px;
  padding: 1px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
}
:root[data-theme="dark"] .konwerter-menu-button {
  border-width: 1px;
  border-color: var(--disabled);
  border-style: solid;
}
.konwerter-menu-button-selected {
  all: unset;
  font-family: Barlow;
  color: var(--text-white);
  font-size: 16px;
  font-weight: 700;
  border-radius: 0;
  background-color: var(--primary-text);
  cursor: pointer;
  transition: 0.25s;
  height: 30px;
  padding: 1px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
}

@media (max-width: 1200px) {
  .konwerter-menu-button,
  .konwerter-menu-button-selected {
    flex: 1 1 calc(33.33% - 10px);
    margin: 5px;
  }
}

@media (max-width: 768px) {
  .konwerter-menu-button,
  .konwerter-menu-button-selected {
    flex: 1 1 calc(25% - 10px);
    margin: 5px;
  }
  .theme-toggle-text {
    display: none;
  }
  .shultz {
    display: none;
  }
}

@media (max-width: 480px) {
  .konwerter-menu-button,
  .konwerter-menu-button-selected {
    flex: 1 1 calc(20% - 10px);
    margin: 5px 0;
    font-size: 12px;
  }
  /*
  .theme-toggle-button{
    display: none;
  } */
}

.button-icon {
  margin-left: 10px;
  margin-right: 14px;
}
